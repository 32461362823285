import React, { useEffect, useState } from 'react';
import Lottie from 'lottie-react';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';

import animationData from '../jsonfils/Lotifile/OTP.json';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import CustomModal from '../component/UI Component/Modal';
import SignaturePad from '../component/custom_signpad';

const Login = () => {

  const [newPassword, setNewPassword] = useState("password")



  const { register, handleSubmit, formState: { errors } } = useForm();
  const apiname = process.env.REACT_APP_API_LOGIN.toString();
  const { error, mutate, isError, isLoading, data, isSuccess } = useMutation(async (data) => {
    const postapicall = await axios.post(`${process.env.REACT_APP_BASE_URL}${apiname}`, data)
    return postapicall.data
  })
  const navigate = useNavigate()


  const onSubmit = (data) => {
    mutate(data)
  }

  useEffect(() => {
    if (data) {
      localStorage.setItem('items', JSON.stringify(data))
    }
  }, [data])


  const [state, setState] = React.useState({
    open: false
  });

  useEffect(() => {
    localStorage.clear()
  }, [])

  useEffect(() => {
    if (isSuccess) {
      setState({ open: true });
      // localStorage.removeItem('user')

      setTimeout(() => {
        navigate("/dashboard");
      }, 1000);
    } else if (isError) {
      console.log(error, "isError");
      setState({ open: true });
    }
  }, [isSuccess, isError, error, data, navigate]);

  const handleClose = () => {
    setState(prevState => ({
      ...prevState,
      open: false,
    }));
  };

  const getSnackbarStyle = () => {
    if (error?.response?.data?.message || error?.message) {
      return {
        backgroundColor: 'red',
        color: 'white',
      };
    }
    if (data?.message) {
      return {
        backgroundColor: 'green',
        color: 'white',
      };
    }
    return {};
  };

  const handleSaveSignature = (signature) => {
    console.log("Saved signature:", signature); // Base64 image string
    // Here, you can send `signature` to your backend or save it as needed
  };



  const handelNewPassword = () => {
    if (newPassword === 'password') {
      setNewPassword("text")
    }
    else {
      setNewPassword("password")
    }
  }



  return (
    <>
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="flex max-w-4xl">
          {/* Left Column - Vector Image */}
          <div className="hidden md:flex md:w-1/2 items-center justify-center">
            <Lottie
              animationData={animationData}
              style={{ width: '100%', height: 'auto' }}
            />
          </div>

          {/* Right Column - Login Form */}
          <div className="w-full md:w-1/2  bg-white p-8 rounded-lg shadow-md ms-2">
            <h2 className="text-2xl font-bold text-center mb-6">Login</h2>

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700" htmlFor="email">
                  Email
                </label>
                <div className="flex items-center border border-gray-300 rounded-md p-2">
                  <svg className="h-8 w-8 text-red-500" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <rect x="3" y="5" width="18" height="14" rx="2" />
                    <polyline points="3 7 12 13 21 7" />
                  </svg>
                  <input
                    type="email"
                    id="email"
                    className="flex-1 p-2 border border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                    placeholder="Enter your email"
                    {...register('email', {
                      required: 'Email is required',
                      pattern: {
                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g,
                        message: 'Please enter a valid email',
                      },
                    })}
                  />
                </div>
                <p style={{ color: "Red" }}>{errors?.email?.message}</p>
              </div>

              <div className="mb-4 relative">
                <label className="block text-sm font-medium text-gray-700">
                  Password
                </label>
                <div className="flex items-center border border-gray-300 rounded-md p-1 relative">
                  <svg className="h-8 w-8 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z" />
                  </svg>
                  <input
                    {...register('password', { required: { value: true, message: 'Password Is Required' } })}
                    type={newPassword ? newPassword : "password"}
                    className="flex-1 p-2 border border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                    placeholder="Enter your Password"
                  />
                  <span className="absolute right-3 top-1/2 transform -translate-y-1/2">
                    <button type="button" onClick={handelNewPassword} className="text-gray-600">
                      {newPassword === "password" ? <FaRegEyeSlash /> : <FaRegEye />}
                    </button>
                  </span>
                </div>
                <p style={{ color: "Red" }}>{errors?.password?.message}</p>
              </div>


              <button type='submit' className="w-full block text-center bg-pink-600 text-white py-2 rounded-md hover:bg-pink-700">

                <div className='flex justify-center'>

                  <div className='mx-2'>
                    <span>Login</span>
                  </div>

                  <div>
                    {isLoading && <div class="w-6 h-6 rounded-full animate-spin border-4 border-dashed border-white border-t-transparent"></div>}
                  </div>

                </div>

              </button>
            </form>
          </div>
        </div >

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={state.open}
          onClose={handleClose}
          autoHideDuration={3000}
          ContentProps={{
            style: getSnackbarStyle(),
          }}
          onExited={() => {
            if (state.open && isSuccess) {
              navigate("/dashboard");
            }
          }}
          message={error?.response?.data?.message || data?.message || error?.message}
        />
        <CustomModal />

        {/* <h1>Custom Signature Pad</h1>
        <SignaturePad onSave={handleSaveSignature} /> */}

      </div >
    </>
  );
};

export default Login;
