// import React, { useEffect } from 'react';
// import Lottie from 'lottie-react';
// import animationData from '../../jsonfils/Lotifile/OTP.json';
// import { useForm } from 'react-hook-form';
// import { useMutation } from 'react-query';
// import axios from 'axios';
// import { useNavigate, useParams } from "react-router-dom";
// import  Snackbar  from '@mui/material/Snackbar';

// const OTP = () => {
//   const navigate = useNavigate()
//   const store_uuid = localStorage.getItem("store_uuid")
//   const form_category = localStorage.getItem("form_category")
//   const form_name = localStorage.getItem("form_name")
//   const email = localStorage.getItem("userEmail")
//   const mobile = localStorage.getItem("userMobile")
//   const localUserData = localStorage.getItem('user')
//   const userData = localUserData ? JSON.parse(localUserData) : null;
//   // const { email, storeId } = useParams()
//   const { register, handleSubmit, formState: { errors } } = useForm();
//   const apiname = process.env.REACT_APP_API_VERIFY_OTP.toString();
//   const { error, mutate, isError, isLoading, data, isSuccess } = useMutation(async (data) => {
//     const postapicall = await axios.post(`${process.env.REACT_APP_BASE_URL}${apiname}`, data)
//     return postapicall.data
//   })

//   const onSubmit = (data) => {

//     mutate({email:email,otp:data.otp})
//   }

//   useEffect(()=>{
//     if(data)
//       localStorage.setItem('user',JSON.stringify(data))
//   },[data])

//   const [state, setState] = React.useState({
//     open: false
//   });
//   const handleClose = () => {
//     setState({
//       ...state,
//       open: false,
//     });
//   };

//   useEffect(()=>{
//     if (isSuccess && data) {
//       localStorage.removeItem('items')

//       // if(data.completed_forms?.length){
//         //   navigate(`/user/form`)
//         // }else{
//           //   navigate(`/defaultform`)
//           // }
//           setState({open:true})
//           setTimeout(() => {
//             // if(form_category === 'Release'){
//             //   navigate(`/user/${data?.id}/${data?.store_uuid}/Release/${form_name}`)
//             // }else{
//             //   navigate(`/user/${data?.id}/${data?.store_uuid}/${form_category}`)
//             // }
//               navigate(`/user/${data?.id}/details`)
//       }, 1000);
//     }
//     else if(isError){
//       console.log(isError,"isError");
//       setState({open:true})
//     }
//   },[data,error,isSuccess,navigate])

//   const getSnackbarStyle = () => {
//     if (error?.response?.data?.message || error?.message) {
//       return {
//         backgroundColor: 'red',
//         color: 'white',
//       };
//     }
//     if (data?.message) {
//       return {
//         backgroundColor: 'green',
//         color: 'white',
//       };
//     }
//     return {};
//   };

//   return (
//     <>
//     <div className="flex items-center justify-center min-h-screen bg-gray-100">
//       <div className="flex flex-col md:flex-row max-w-4xl p-6 rounded-lg ">

//         <div className="hidden md:flex md:w-1/2 items-center justify-center p-4">
//           <Lottie
//             animationData={animationData}
//             style={{ width: '100%', height: 'auto' }}
//           />
//         </div>

//         <div className="w-full md:w-1/2 p-6  bg-white shadow-lg">
//           <h2 className="text-2xl font-bold text-center mb-6">OTP Verification </h2>

//           <form onSubmit={handleSubmit(onSubmit)}>
//             <div className="mb-4">
//               <label className="block text-sm font-medium text-gray-700" htmlFor="otp">
//                 OTP
//               </label>
//               <div className="flex items-center border border-gray-300 rounded-md p-2">
//                 <svg className="h-8 w-8 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//                   <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 11v2m-6 4a9 9 0 1112 0M12 7h.01" />
//                 </svg>
//                 <input
//                   type="text"
//                   id="otp"
//                   name="otp"
//                   className="flex-1 p-2 border border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
//                   placeholder="Enter OTP"
//                   {...register('otp', { required: { value: true, message: "OTP is required" } })}
//                 />
//               </div>
//               <p style={{ color: "Red" }}>{errors?.otp?.message}</p>
//             </div>
//             <button type='submit' className="w-full block text-center bg-pink-600 text-white py-2 rounded-md hover:bg-pink-700">

//               <div className='flex justify-center'>

//                 <div className='mx-2'>
//                   <span> Login</span>
//                 </div>

//                 <div>
//                   {isLoading && <div className="w-6 h-6 rounded-full animate-spin border-4 border-dashed border-white border-t-transparent"></div>}
//                 </div>

//               </div>

//             </button>
//           </form>
//         </div>
//       </div>
//     </div>
//     <Snackbar
//      anchorOrigin={{ vertical:'top', horizontal:'right' }}
//        open={state.open}
//        onClose={handleClose}
//        ContentProps={{
//         style: getSnackbarStyle(),
//       }}
//        TransitionComponent={state.Transition}
//        key={state?.Transition?.name}
//        autoHideDuration={3000}
//        message={error?.response?.data?.message || data?.message}
//        />
//     </>
//   );
// };

// export default OTP;

import React, { useEffect, useState } from "react";
import Lottie from "lottie-react";
import animationData from "../../jsonfils/Lotifile/OTP.json";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import { Tabs } from "antd";

const OTP = () => {
  const navigate = useNavigate();
  const email = localStorage.getItem("userEmail");
  const mobile = localStorage.getItem("userMobile");
  const getdob = localStorage.getItem("dob");
  const [timer, setTimer] = useState(60);
  const [isOtpExpired, setIsOtpExpired] = useState(false);
  const [state, setState] = React.useState({ open: false });
  const [loding, setLoding] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const apiname = process.env.REACT_APP_API_VERIFY_OTP.toString();
  const apiname_sendOTP = process.env.REACT_APP_API_SEND_OTP.toString();
  const { mutate, isLoading, data, isError, isSuccess, error } = useMutation(
    async (data) => {
      let finalData = {
        email: data?.email,
        mobile: mobile,
        dob: getdob,
        sms_otp: data.sms_otp,
        email_otp: data.email_otp,
      };

      if (!finalData.sms_otp) {
        delete finalData.sms_otp;
      }

      if (!finalData.email_otp) {
        delete finalData.email_otp;
      }
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}${apiname}`,
        finalData
      );
      return response.data;
    }
  );

  const handleChaneValue = (e) => {
    // console.log(e,"get")
    if (e === "email") {
      setValue("sms_otp", "");
    } else if (e === "mobile") {
      setValue("email_otp", "");
    }
  };

  useEffect(() => {
    if (isSuccess && data) {
      localStorage.removeItem("items");
      localStorage.removeItem("timer");
      // if(data.completed_forms?.length){
      //   navigate(`/user/form`)
      // }else{
      //   navigate(`/defaultform`)
      // }
      setState({ open: true });
      setTimeout(() => {
        // if(form_category === 'Release'){
        //   navigate(`/user/${data?.id}/${data?.store_uuid}/Release/${form_name}`)
        // }else{
        //   navigate(`/user/${data?.id}/${data?.store_uuid}/${form_category}`)
        // }
        navigate(`/user/${data?.id}/details`);
      }, 1000);
    } else if (isError) {
      console.log(isError, "isError");
      setState({ open: true });
    }
  }, [data, error, isSuccess, navigate]);

  // Function to start or reset the timer
  const startTimer = () => {
    const savedTimer = localStorage.getItem("timer"); // Retrieve the saved timer from localStorage
    if (savedTimer) {
      setTimer(parseInt(savedTimer, 10));
    } else {
      setTimer(60);
    }
    console.log(savedTimer, "savedTimer");
    if (savedTimer == 0) {
      localStorage.removeItem("timer");
    }
  };

  console.log(getdob, "getdob");
  const getApi = localStorage.getItem("store_uuid");
  console.log(getApi, "getApi");
  // Resend OTP function

  const [resendLoader, setResendLoader] = useState(false);
  const resendOtp = async () => {
    setLoding(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}${apiname_sendOTP}`,
        {
          email: email,
          mobile: mobile,
          store_uuid: getApi,
          dob: getdob,
        }
      );

      if (response.status === 200) {
        setLoding(false);
        setTimer(60); // Reset timer when OTP is resent
        setIsOtpExpired(false);
        localStorage.setItem("timer", 60); // Store the new timer value in localStorage
        alert("OTP has been resent successfully!");
      } else {
        throw new Error("Failed to resend OTP. Please try again later.");
      }
    } catch (err) {
      alert(err.response?.data?.message || err.message);
      setLoding(false);
    }
  };

  // Handle OTP form submission
  const onSubmit = (data) => {
    console.log(data, "data");

    mutate({ email: email, sms_otp: data.sms_otp, email_otp: data.email_otp });
  };
  // const onSubmitEmail = (data) => {
  //   console.log(data,"data")
  //   // mutate({ email: email, otp: data.otp });
  // };

  // Timer countdown for OTP expiration
  useEffect(() => {
    // Start the timer when component mounts (or when timer is reset)
    startTimer();

    if (timer === 0) {
      setIsOtpExpired(true);
      return;
    }

    const interval = setInterval(() => {
      if (timer > 0) {
        setTimer((prev) => {
          const newTimer = prev - 1;
          localStorage.setItem("timer", newTimer); // Save updated timer value in localStorage
          return newTimer;
        });
      }
    }, 1000);

    return () => clearInterval(interval); // Cleanup on component unmount
  }, [timer]);

  useEffect(() => {
    if (isSuccess && data) {
      localStorage.setItem("user", JSON.stringify(data));
      // navigate(`/personaldetails/${data?.id}`);
    } else if (isError) {
      setState({ open: true });
    }
  }, [data, error, isSuccess, isError, navigate]);

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const getSnackbarStyle = () => {
    if (error?.response?.data?.message || error?.message) {
      return {
        backgroundColor: "red",
        color: "white",
      };
    }
    if (data?.message) {
      return {
        backgroundColor: "green",
        color: "white",
      };
    }
    return {};
  };

  const handleClose = () => {
    setState({
      ...state,
      open: false,
    });
  };
  const tabOption = [
    {
      label: "Mobile",
      key: "mobile",
      children: (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4 ">
            <label
              className="block text-sm font-medium text-gray-700"
              htmlFor="otp"
            >
              Mobile OTP
            </label>
            <div className="flex items-center border border-gray-300 rounded-md p-2">
              <svg
                className="h-8 w-8 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 11v2m-6 4a9 9 0 1112 0M12 7h.01"
                />
              </svg>
              <input
                type="text"
                id="otp"
                name="otp"
                className="flex-1 p-2 border border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                placeholder="Enter Mobile  OTP"
                {...register("sms_otp", {
                  required: { value: false, message: "OTP is required" },
                })}
              />
            </div>
            <div className="items-end text-end">
              <div>
                <span className="text-blue-600 text-xs">
                  OTP is only valid for 10 mins
                </span>
              </div>
              <div className="mt-2 text-sm text-gray-500">
                {isOtpExpired ? (
                  <span className="text-green-500">
                    {loding ? (
                      <div className="flex justify-end items-center ">
                        <div className="w-5 h-5 rounded-full animate-spin border-4 border-dashed border-blue-600 border-t-transparent mr-5"></div>
                      </div>
                    ) : (
                      <button className="underline" onClick={resendOtp}>
                        Resend OTP
                      </button>
                    )}
                  </span>
                ) : (
                  <span className="text-gray-600">
                    You can resend OTP in: {formatTime(timer)} min
                  </span>
                )}
              </div>
            </div>
            <button
              type="submit"
              className="w-full block text-center bg-pink-600 text-white py-2 rounded-md hover:bg-pink-700"
            >
              <div className="flex justify-center">
                <div className="mx-2">
                  <span>Login</span>
                </div>
                <div>
                  {isLoading && (
                    <div className="w-6 h-6 rounded-full animate-spin border-4 border-dashed border-white border-t-transparent"></div>
                  )}
                </div>
              </div>
            </button>
          </div>
        </form>
      ),
    },
    {
      label: "Email",
      key: "email",
      children: (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4">
            <label
              className="block text-sm font-medium text-gray-700"
              htmlFor="otp"
            >
              Email OTP
            </label>
            <div className="flex items-center border border-gray-300 rounded-md p-2">
              <svg
                className="h-8 w-8 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 11v2m-6 4a9 9 0 1112 0M12 7h.01"
                />
              </svg>
              <input
                type="text"
                id="otp"
                name="otp"
                className="flex-1 p-2 border border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                placeholder="Enter Email OTP"
                {...register("email_otp", {
                  required: { value: false, message: "OTP is required" },
                })}
              />
            </div>
            <div className="items-end text-end">
              <div>
                <span className="text-blue-600 text-xs">
                  OTP is only valid for 10 mins
                </span>
              </div>
              <div className="mt-2 text-sm text-gray-500">
                {isOtpExpired ? (
                  <span className="text-green-500">
                    {loding ? (
                      <div className="flex justify-end items-center ">
                        <div className="w-5 h-5 rounded-full animate-spin border-4 border-dashed border-blue-600 border-t-transparent mr-5"></div>
                      </div>
                    ) : (
                      <button className="underline" onClick={resendOtp}>
                        Resend OTP
                      </button>
                    )}
                  </span>
                ) : (
                  <span className="text-gray-600">
                    You can resend OTP in: {formatTime(timer)} min
                  </span>
                )}
              </div>
            </div>
            <button
              type="submit"
              className="w-full block text-center bg-pink-600 text-white py-2 rounded-md hover:bg-pink-700"
              // onClick={handleSubmit(onSubmit)}
            >
              <div className="flex justify-center">
                <div className="mx-2">
                  <span>Login</span>
                </div>
                <div>
                  {isLoading && (
                    <div className="w-6 h-6 rounded-full animate-spin border-4 border-dashed border-white border-t-transparent"></div>
                  )}
                </div>
              </div>
            </button>
          </div>
        </form>
      ),
    },
  ];

  return (
    <>
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="flex flex-col md:flex-row max-w-4xl p-6 rounded-lg ">
          <div className="hidden md:flex md:w-1/2 items-center justify-center p-4">
            <Lottie
              animationData={animationData}
              style={{ width: "100%", height: "auto" }}
            />
          </div>

          <div className="w-full md:w-1/2 p-6 bg-white shadow-lg">
            <h2 className="text-2xl font-bold text-center mb-6">
              OTP Verification
            </h2>
            <p className="text-orange-400 text-xs  ">
              * Select any one option for OTP verification.
            </p>

            <Tabs type="card" items={tabOption} onChange={handleChaneValue} />
          </div>
        </div>
      </div>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={state.open}
        onClose={handleClose}
        ContentProps={{
          style: getSnackbarStyle(),
        }}
        TransitionComponent={state.Transition}
        key={state?.Transition?.name}
        autoHideDuration={3000}
        message={error?.response?.data?.message || data?.message}
      />
    </>
  );
};

export default OTP;
