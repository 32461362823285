import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

export const useColumnSearch = ({ apiname, refetchData, initialSearchState = {}, searchQuery,currentPage }) => {
    const [columnSearch, setColumnSearch] = useState(initialSearchState);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [filteredRows, setFilteredRows] = useState([]);
    console.log(filteredRows,"filteredRows")
    const handleColumnSearchChange = (key, value) => {
        setColumnSearch(prev => ({
            ...prev,
            [key]: value,
        }));
    };

    // Clear the search filters
    const clearSearch = () => {
        setColumnSearch({});
    };

    // Fetch filtered data based on the current columnSearch
    const fetchFilteredData = useCallback(async () => {
        if (!apiname) return;

        setIsLoading(true);
        setError(null);
        try {
            const params = { ...columnSearch };
            // Call the API with the column search params   
            const localStorageData = localStorage.getItem('items');
            const tokenData = localStorageData ? JSON.parse(localStorageData) : null;
        
            const localUserData = localStorage.getItem('user');
            const userData = localUserData ? JSON.parse(localUserData) : null;
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}${apiname}?search=${searchQuery}&page=${currentPage}&limit=${10}`, { params, 
                headers: {
                     'authorization': `Bearer ${userData?.token ?? tokenData?.token}`
                }
            });
            console.log(response,"response")
            // Assuming the API returns rows of data
            setFilteredRows(response.data);

            // Refetch additional data if necessary (like pagination state or other filters)
            if (refetchData) {
                await refetchData();
            }
        } catch (err) {
            setError(err.response ? err.response.data : err.message);
        } finally {
            setIsLoading(false);
        }
    }, [apiname, columnSearch, refetchData,searchQuery,currentPage]);

    // Call the API whenever the search state changes
    useEffect(() => {
        fetchFilteredData();
    }, [columnSearch, fetchFilteredData]);

    return {
        columnSearch,
        filteredRows,
        isLoading,
        error,
        handleColumnSearchChange,
        clearSearch,
        setFilteredRows
    };
};
