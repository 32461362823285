import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios'
import { Snackbar } from '@mui/material';

const Edit_User = () => {
  const { id } = useParams();
  const getApi = process.env.REACT_APP_API_USER_UPDATE_DELETE?.toString()
  const apiname = process.env.REACT_APP_API_USER_UPDATE_DELETE?.toString();
  const localStorageData = localStorage.getItem('items')
  const tokenData = localStorageData ? JSON.parse(localStorageData) : null
  const { error, mutate, isError, isLoading, data, isSuccess } = useMutation(async (data) => {
    const updateStoreUserDetails = await axios.put(`${process.env.REACT_APP_BASE_URL}${apiname}/${id}`, data, {
      headers: {
        'authorization': `Bearer ${tokenData?.token}`
      },
    })
    return updateStoreUserDetails.data
  })
  const navigate = useNavigate()
  const [userDetails, setUserDetails] = useState([])
  const [state, setState] = React.useState({
    open: false
  });

  const { register, handleSubmit, setError, formState: { errors }, reset, setValue } = useForm({
    defaultValues: {
      name: userDetails?.name,
      email: userDetails?.email,
    }
  });

  useEffect(() => {
    if (userDetails) {
      reset()
      setValue('mobile', userDetails?.mobile)
      setValue('email', userDetails?.email)
    }
  }, [userDetails])

  const onSubmit = (data) => {
    let finalData = {
      ...data,
      role: 'user',
      storeId:userDetails.storeId,
      completed_forms:userDetails.completed_forms
    }
    mutate(finalData)
  }

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    const res = await axios.get(`${process.env.REACT_APP_BASE_URL}${getApi}/${id}`, {
      headers: {
          'authorization': `Bearer ${tokenData?.token}`
        }
    });
    setUserDetails(res.data.results);
  }
  const handleClose = () => {
    setState({
      ...state,
      open: false,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      setState({ open: true });
      navigate("/layout/listUser")
    } else if (isError) {
      setState({ open: true });
    }
  }, [data, error, isSuccess]);
  return (
    <>
      <div className="">
        <div className="bg-white shadow rounded-lg p-4">
          <div className=" mb-6">
            <div className="text-2xl font-bold text-gray-800">Edit User</div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700" htmlFor="StoreManagerName">
                  Mobile Number
                </label>
                <div className="flex items-center border border-gray-300 rounded-md p-2">
                  {/* <svg className="h-8 w-8 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg> */}

<svg className="h-8 w-8 text-gray-400" viewBox="-13.32 0 68.514 68.514" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="Group_62" data-name="Group 62" transform="translate(-815.597 -920.238)"> <g id="Group_59" data-name="Group 59"> <path id="Path_53" data-name="Path 53" d="M852.706,988.752H820.363a4.773,4.773,0,0,1-4.766-4.767v-58.98a4.773,4.773,0,0,1,4.766-4.767h32.343a4.773,4.773,0,0,1,4.767,4.767v58.98A4.773,4.773,0,0,1,852.706,988.752Zm-32.343-65.514a1.769,1.769,0,0,0-1.766,1.767v58.98a1.769,1.769,0,0,0,1.766,1.767h32.343a1.769,1.769,0,0,0,1.767-1.767v-58.98a1.769,1.769,0,0,0-1.767-1.767Z" fill="#727883"></path> </g> <g id="Group_60" data-name="Group 60"> <rect id="Rectangle_35" data-name="Rectangle 35" width="26.5" height="43.764" rx="2.058" transform="translate(823.285 929.072)" fill="#727883"></rect> </g> <g id="Group_61" data-name="Group 61"> <circle id="Ellipse_76" data-name="Ellipse 76" cx="3.699" cy="3.699" r="3.699" transform="translate(832.836 975.706)" fill="#727883"></circle> </g> </g> </g></svg>                 
 <input
                    {...register('mobile', {
                      required: 'Mobile number is required',
                      pattern: {
                        value: /^(\+?1?\s?(\d{3}|\(\d{3}\))[-.\s]?\d{3}[-.\s]?\d{4}|[6-9]\d{9})$/,
                        message: 'Please enter a valid mobile number',
                      },
                    })}
                    name='mobile'
                    type="text"
                    // defaultValue={userDetails.store_manager_name}
                    id="name"
                    className="flex-1 p-2 border border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                    placeholder="Enter your Mobile Number"
                  />
                </div>
                <p style={{ color: "Red" }}>{errors?.mobile?.message}</p>
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700" htmlFor="email">
                  Email
                </label>
                <div className="flex items-center border border-gray-300 rounded-md p-2">
                  <svg class="h-8 w-8 text-gray-400" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <polyline points="3 9 12 15 21 9 12 3 3 9" />  <path d="M21 9v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10" />  <line x1="3" y1="19" x2="9" y2="13" />  <line x1="15" y1="13" x2="21" y2="19" /></svg>
                  <input
                    name='email'
                    {...register('email', { required: { value: true, message: 'Email is required.' } })}
                    type="email"
                    id="email"
                    // defaultValue={userDetails.email}
                    className="flex-1 p-2 border border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                    placeholder="Enter your Email"
                  />
                </div>
                <p style={{ color: "Red" }}>{errors?.email?.message}</p>
              </div>

            </div>
            <div className='mt-6 text-center flex justify-center'>
              <button className="flex flex-row justify-center items-center bg-pink-600 text-white px-6 py-2 rounded-lg shadow-md hover:bg-pink-400" type='submit'
                disabled={isLoading}
              >
                Update
                {isLoading && <div className="mx-2 w-4 h-4 rounded-full animate-spin border-4 border-dashed border-white border-t-transparent"></div>}
              </button>
            </div>

          </form>
        </div>
      </div>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={state.open}
        onClose={handleClose}
        TransitionComponent={state.Transition}
        message={error?.response?.data?.message || data?.message}
        key={state?.Transition?.name}
        autoHideDuration={3000}
      />
    </>
  );
}

export default Edit_User;
