import React, { useState, useEffect } from 'react';
import Users from '../images/users.png';
import Sales from '../images/Sales.png';
import NewSignup from '../images/Mobile login 1.png';
import { useFetch } from '../hooks/Api';
import Header from '../layout/subComponents/Header';
import Sidebar from '../layout/subComponents/Sidebar';
import MobileMenu from '../layout/subComponents/MobileMenu';
import { Link } from 'react-router-dom';
// import SignaturePad from '../component/custom_signpad';
const Dashboard = () => {
    const localStorageData = localStorage.getItem('items');
    const tokenData = localStorageData ? JSON.parse(localStorageData) : null;

    const { data: storeData } = useFetch(process.env.REACT_APP_API_STORE_MANAGERS);
    const { data: userData } = useFetch(process.env.REACT_APP_API_USERS);
    const { data: formData } = useFetch(process.env.REACT_APP_API_FORMS);

    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!isMobileMenuOpen);
    };

    const closeMobileMenu = () => {
        setMobileMenuOpen(false);
    };

    console.log('tokenData',tokenData)



    return (
        <div className="bg-gray-100 min-h-screen">
            <Header toggleMobileMenu={toggleMobileMenu} />
            <div className="flex">
                {/* Sidebar */}
                <Sidebar />

                <main className="flex-1 p-4 sm:p-6 lg:p-8 w-full overflow-x-auto">
                    <div className="space-y-6">
                        {/* Header */}
                        <div className="text-2xl sm:text-3xl font-bold text-gray-800">Dashboard</div>

                        {/* Statistics */}
                        {tokenData?.roles === 'admin' && (
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                                <Link to="/layout/listUser" className="block">
                                    <div className="bg-white shadow rounded-lg p-6">
                                        <div className="flex justify-between items-center">
                                            <div>
                                                <div className="text-pink-600 font-bold">Users</div>
                                                <div className="text-2xl font-bold text-gray-800">
                                                    {userData?.totalRows || 0}
                                                </div>
                                            </div>
                                            <div>
                                                <img src={Users} alt="Users" className="h-16 w-16" />
                                            </div>
                                        </div>
                                    </div>
                                </Link>

                                <Link to="/layout/liststore" className="block">
                                    <div className="bg-white shadow rounded-lg p-6">
                                        <div className="flex justify-between items-center">
                                            <div>
                                                <div className="text-pink-600 font-bold">Store Managers</div>
                                                <div className="text-2xl font-bold text-gray-800">
                                                    {storeData?.totalRows || 0}
                                                </div>
                                            </div>
                                            <div>
                                                <img src={Sales} alt="Sales" className="h-16 w-16" />
                                            </div>
                                        </div>
                                    </div>
                                </Link>

                                <Link to="/layout/form" className="block">
                                    <div className="bg-white shadow rounded-lg p-6">
                                        <div className="flex justify-between items-center">
                                            <div>
                                                <div className="text-pink-600 font-bold">Total Forms</div>
                                                <div className="text-2xl font-bold text-gray-800">
                                                    {formData?.totalRows || 0}
                                                </div>
                                            </div>
                                            <div>
                                                <img src={NewSignup} alt="Forms" className="h-16 w-16" />
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        )}


                        {/* <SignaturePad/> */}

                        {/* Store QR Code for store users */}
                        {tokenData?.roles === 'store' && (
                            <div className="bg-white shadow rounded-lg p-6">
                                <h2 className="text-lg font-bold mb-4">Store State - <span className='text-lg font-bold mb-4 text-blue-600'>{tokenData?.state_name}</span></h2>
                                <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                                    {tokenData?.qrcode?.length > 0 &&
                                        tokenData?.qrcode.map((e) => (
                                            <div
                                                key={e?.qrcode}
                                                className="flex flex-col items-center justify-center text-center"
                                            >
                                                <img
                                                    src={e?.qrcode}
                                                    alt="Store QR Code"
                                                    className="mb-4 w-48 h-48 object-cover"
                                                />
                                                <a href={e?.qrcode} download={e?.form_category}>
                                                    <button className="bg-pink-500 text-white px-6 py-3 rounded-lg hover:bg-pink-600">
                                                        Download {e?.form_category} QR
                                                    </button>
                                                </a>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        )}

                        {/* Chart */}
                        {/* Add a chart here */}
                    </div>
                </main>
            </div>

            {/* Mobile Menu */}
            <MobileMenu isOpen={isMobileMenuOpen} closeMobileMenu={closeMobileMenu} />
        </div>
    );
};

export default Dashboard;
