import './App.css';
import { QueryClient, QueryClientProvider } from "react-query";
import Login from './pages/Login_Admin.jsx';
import { BrowserRouter, Route, Router, Routes, useNavigate } from 'react-router-dom';
import Layout from './layout/Layout.jsx';
import PrivateRoute from './Routes/PrivateRoute.jsx'
import EmailPrivateRoute from './Routes/EmailPrivateRoute.jsx'
import OTP from './pages/User Login/OTP.jsx'
import NewForm from './pages/NewForm/NewForm.jsx';
import FormScreen from './pages/User Form/FormScreen.jsx';
import FormDetails from './pages/User Form/FormDetails.jsx';
import Login_Redirect from './pages/User Login/Login_redirect.jsx';
import Login_User from './pages/User Login/Login_User.jsx';
import { ModalProvider } from './component/UI Component/OpenModal.jsx';
import FormsByCategory from './pages/Forms_By_Category/FormsByCategory.jsx';
import PersonalDetails from './pages/User Login/personalDetails.jsx';
import FormDetailsByName from './pages/User Form/FormDetailsByName.jsx';
import AlreadySubmitted from './pages/User Form/AlreadySubmitted.jsx';
import Dashboard from './pages/Dashboard.jsx';

const queryclient = new QueryClient();
function App() {

 

  return (
    <>
      <QueryClientProvider client={queryclient}>
        <ModalProvider>
          <BrowserRouter>
            <Routes>
              <Route path='/' element={<Login />} />

              <Route path="/dashboard" element={<Dashboard />} />

             
              <Route path='/login' element={<Login_User />} />


              {/* <Route element={<PrivateRoute element={<Layout />} />}/> */}


              {/* </Route> */}
              <Route path='/layout/*' element={<Layout />} />

             
            



              



              <Route element={<EmailPrivateRoute />}>


                <Route path='/user-otp' element={<OTP />} />
                <Route path='/user/:userId/:storeId/:form_category' element={<FormsByCategory />} />
                <Route path='/user/:userId/details' element={<PersonalDetails />} />
              <Route path='/user/already_submitted' element={<AlreadySubmitted />} />
              <Route path='/user/form/:user_id/:form_name' element={<FormDetailsByName />} />
              
              <Route path='/defaultform' element={<NewForm />} />
              <Route path='/user/form' element={<FormScreen />} />
              <Route path='/user/form/:id' element={<FormDetails />} />
              </Route>

              {/* <Route path='/redirect/:storeId/:form_category/:form_name' element={<Login_Redirect />} /> */}
              <Route path='/redirect/:storeId/:form_category' element={<Login_Redirect />} />

                {/* <Route path='/user/:userId/:storeId/:form_category/:form_name' element={<PersonalDetails />} /> */}
                {/* <Route path='/user/:userId/:storeId/Release/:form_name' element={<FormsByName />} /> */}

            
            </Routes>
          </BrowserRouter>
        </ModalProvider>
      </QueryClientProvider>
    </>
  );
}

export default App;
